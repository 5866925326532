let inView

try {
  require("intersection-observer")
  inView = (target, callback) => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    }
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry)
          observer.disconnect()
        }
      })
    }, options)

    io.observe(target)
  }
} catch (e) {
  console.log(e)
}
export default inView
